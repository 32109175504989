<template>
  <div style="display: flex; justify-content: center">
    <v-progress-circular
      v-if="!registered && !error1 && !error2 && !iOS"
      :size="70"
      :width="7"
      color="purple"
      indeterminate
    ></v-progress-circular>

    <v-alert
      v-if="registered"
      dense
      text
      type="success"
    >
      Обновление сервисоворкера выполнено успешно. Спасибо!
    </v-alert>

    <v-alert
      v-if="error1 && !registered"
      dense
      type="info"
    >
      Похоже последняя версия сервисворкера уже установлена. Спасибо!
    </v-alert>

    <v-alert
      v-if="error2 && !iOS &&!error1 && !registered"
      dense
      type="warning"
    >
      Какая-то ошибка, ничего страшного, просто покиньте страницу, и разработчик будет Вам благодарен, если Вы сообщите Елене, что получили такое сообщение.
    </v-alert>

    <v-alert
      v-if="error2 && iOS && !error1 && !registered"
      dense
      type="info"
    >
      Похоже у Вас айфон. Для Вас это будет актуально, только в случае добавления сайта на главный экран в виде приложения, и выполнения авторизации.
    </v-alert>

  </div>
</template>

<script>
export default {
  name: "UpdateServiceWorkers",
  data: () => ({
    registered: false,
    error1: false,
    error2: false,
    iOS: false
  }),

  methods: {
    showUpdateNotification() {
      this.registered = true
    }
  },
  async mounted() {
    try {
      if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {scope: '/firebase-cloud-messaging-push-scope'})

        if (registration.waiting) {
          this.showUpdateNotification()
        }

        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing
          newWorker.addEventListener('statechange', () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              this.showUpdateNotification()
            }
          })
        })
      }

      await new Promise(resolve => setTimeout(resolve, 2000))

      if (!this.registered) {
        this.error1 = true
      }
    } catch (e) {
      this.error2 = true
      const iOS = /iPhone|iPod|iPad/.test(navigator.userAgent)
      if (iOS) this.iOS = true
    }
  }
}
</script>

<style scoped>

</style>
